#park_choir {
  width: 100vw;
  height: auto;
  margin: 0;
}

#park_choir_mobile {
  display: none;
}

.unison_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .unison_img {
    padding: 15px;
  }

  .unison_row {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {
  #park_choir {
    display: none;
  }

  #park_choir_mobile {
    display: block;
    width: 100vw;
    height: auto;
    margin: 0;
  }
}
