.review_background {
  height: 270px;
}

@media screen and (max-width: 992px) {
  .review_background {
    height: 350px;
  }
}

@media screen and (max-width: 780px) {
  .review_background {
    height: 450px;
  }
}

@media screen and (max-width: 520px) {
  .review_background {
    height: 550px;
  }
}

@media screen and (max-width: 450px) {
  .review_background {
    height: 640px;
  }
}
