.jb_image img {
  height: 300px;
  margin: 20px;
}

.jb_image {
  text-align: center;
}

#sea_cloth,
#brambly {
  width: 100vw;
  height: auto;
  margin: 0;
}

#sea_cloth_mobile,
#brambly_mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .jb_image {
    padding: 20px;
  }

  .jb_image img {
    width: 400px;
    height: auto;
  }

  .video_row {
    display: flex;
    flex-direction: column-reverse;
  }

  .jb_locations {
    padding-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  #sea_cloth,
  #brambly {
    display: none;
  }

  #sea_cloth_mobile,
  #brambly_mobile {
    display: block;
    width: 100vw;
    height: auto;
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .jb_image {
    padding: 10px 0;
  }

  .jb_image img {
    width: 300px;
    height: auto;
  }
}
