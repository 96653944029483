.main_image_container {
  padding: 20px 150px;
}

@media screen and (max-width: 992px) {
  .main_image_container {
    padding: 20px 0;
  }
}

@media screen and (max-width: 500px) {
  .main_image_container {
    padding: 0;
  }
}
